<!-- <div class="col" style="position: absolute; z-index: 9; padding-left: 82%; padding-top: 1.3em;">
  <div ngbDropdown class="d-inline-block">
    <button class="btn btn-sm" style="color: #8ad6e3;" id="dropdownBasic1" ngbDropdownToggle>{{ 'idioma'|translate  }}</button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
      <button ngbDropdownItem (click)="changeLang('es')">Español</button>
      <button ngbDropdownItem (click)="changeLang('en')">English</button>
      <button ngbDropdownItem (click)="changeLang('de')">Deutsche</button>
      <button ngbDropdownItem (click)="changeLang('it')">Italiano</button>
      <button ngbDropdownItem (click)="changeLang('fr')">Frances</button>
    </div>
  </div>
</div> -->
<app-navbar>
</app-navbar>
<router-outlet></router-outlet>
<app-footer></app-footer>

