import { Component, OnInit } from '@angular/core';
import { Document } from './model/document';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  lang: string[] = [];
  lanNav: string = navigator.language;

  constructor(private translate: TranslateService) {
    this.translate.addLangs(['es', 'en', 'de', 'it','fr','po']);

    // Obtener el idioma de la URL
    const urlParams = new URLSearchParams(window.location.search);
    const langFromUrl = urlParams.get('idioma');

    // Establecer el idioma según lo que viene en la URL o usar el idioma del navegador como predeterminado
    const langToUse = langFromUrl || this.lanNav.substring(0, 2);

    this.translate.setDefaultLang('en'); // Idioma predeterminado
    this.translate.use(langToUse);
  }

  ngOnInit() {

  }

  changeLang(lang: string) {
    this.translate.use(lang);
  }
}
